import { createApp, reactive } from 'vue';
import App from './App.vue';
import 'bootstrap/dist/css/bootstrap.css';
import router from './router';
import VueLazyload from 'vue-lazyload';
import 'animate.css/animate.min.css';

const app = createApp(App);

app.use(router);
app.use(VueLazyload, {
    loading: require('@/assets/loading.gif'), // Placeholder image while loading
    attempt: 1,
});

//Global state for cart
app.config.globalProperties.$cart=reactive({
    items:[],
    addItem(product){
        const item=this.items.find(item=>item.id===product.id);
        if(item){
            item.quantity+=1;
        }else{
            this.items.push({...product, quantity:1});
        }
    },
    removeItem(productId){
        this.items=this.items.filter(item=>item.id!==productId);
    },
    getTotal(){
        return this.items.reduce((total, item)=>total+item.price*item.quantity, 0);
    }
})

app.mount('#app');
