<template>
    <div class="cart">
        <div class="container py-5">
            <h2 class="text-center mb-5">Your Cart</h2>
            <div v-if="$cart.items.length>0">
                <table class="table table-striped">
                    <thead>
                        <tr>
                            <th scope="col">Product</th>
                            <th scope="col">Price</th>
                            <th scope="col">Quantity</th>
                            <th scope="col">Subtotal</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in $cart.items" :key="item.id">
                            <td>{{ item.name }}</td>
                            <td>{{ item.price }}</td>
                            <td>{{ item.quantity }}</td>
                            <td>{{ item.price*item.quantity }}</td>
                            <td>
                                <button class="btn btn-danger btn-sm" @click="$cart.removeItem(item.id)">Remove</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            <h4 class="text-end">Total:{{$cart.getTotal()}}</h4>

                <!--Checkout Form-->
                <h3 class="my-4">Checkout</h3>
                <form @submit.prevent="submitOrder">
                    <div class="mb-3">
                        <label for="name" class="form-label">Name</label>
                        <input type="text" class="form-control" id="name" v-model="order.name" required>
                    </div>
                    <div class="mb-3">
                        <label for="address" class="form-label">Address</label>
                        <input type="text" class="form-control" id="address" v-model="order.address" required>
                    </div>
                    <div class="mb-3">
                        <label for="payment" class="form-label">Payment Information</label>
                        <input type="text" class="form-control" id="payment" v-model="order.paymentInfo" required>
                    </div>

                    <button type="submit" class="btn btn-success">Place Order</button>
                </form>
            </div>
            <div v-else>
                <p>Your cart is empty.</p>
            </div>
        </div>
    </div>
</template>

<script>
    export default{
        data(){
            return{
                order:{
                    name:'',
                    address:'',
                    paymentInfo:''
                }
            };
        },
        methods:{
            submitOrder(){
                alert('Thank you,' + this.order.name + 'Your order has been placed');

                this.$cart.items=[];
                this.order.name='';
                this.order.address='';
                this.order.paymentInfo='';
            }
        }
    };
</script>