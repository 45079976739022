<template>
    <div class="contact py-5 bg-light text-navy">
        <!--Contact Us Section-->
        <div class="contact-section text-center py-5">
            <h1 class="animate__animated animate__fadeInUp mb-5">Get in Touch</h1>
            <p class="lead animate__animated animate__fadeInUp animate__delay-1s">We'd love to hear from you! Reach out to us for any inquirires or feedback.</p>
        </div>
        <!--Contact Information-->
        <div class="contact-info-section container py-5">
            <div class="row">
                <div class="col-md-4 text-center animate__animated animate__fadeInLeft">
                    <i class="bi bi-geo-alt-fill" style="font-size: 2rem;"></i>
                    <h4>Our Address</h4>
                    <p>112, Lagos road beside FIRS building, Haruna Bus Stop, Ikorodu.</p>
                </div>

                <div class="col-md-4 text-center animate__animated animate__fadeInUp">
                    <i class="bi bi-telephone-fill" style="font-size: 2rem;"></i>
                    <h4>Call Us</h4>
                    <p>080 6932 5608</p>
                </div>
                <div class="col-md-4 text-center animate__animated animate__fadeInRight">
                    <i class="bi bi-envelope-fill" style="font-size: 2rem;"></i>
                    <h4>Email Us</h4>
                    <p>glorypatrickp@gmail.com</p>
                </div>
            </div>
        </div>

        <!--Contact Form-->
        <div class="contact-form-section container py-5">
            <h2 class="text-center animate__animated animate__fadeInUp">Send Us a Message</h2>
            <div class="row justify-content-center">
                <div class="col-md-6">
                    <form @submit.prevent="submitForm" class="animate__animated animate__fadeInUp animate__delay-1s">
                        <div class="mb-3">
                            <label for="name" class="form-label">Name</label>
                                <input id="name" type="text" class="form-control" v-model="form.name" required placeholder="Enter your name">
                            
                        </div>
                        <div class="mb-3">
                            <label for="email" class="form-label">Email</label>
                                <input id="email" type="email" class="form-control" v-model="form.email" required placeholder="Enter your email">
                            
                        </div>

                        <div class="mb-3">
                            <label for="message" class="form-label">Message</label>
                            <textarea id="message"  class="form-control" v-model="form.message" rows="4" required placeholder="Enter your message"></textarea>
                            
                        </div>

                        <button type="submit" class="btn btn-primary btn-block">Send Message</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default{
        name:'ContactView',
        data(){
            return{
                form:{
                    name:'',
                    email:'',
                    message:''
                }
            }
        },
        methods:{
            submitForm(){
                if(this.form.name && this.form.email && this.form.message){
                    alert('Thank you for your message! We will get back to you soon');
                    this.form.name='';
                    this.form.email='';
                    this.form.message='';
                }else{
                    alert('Please fill in all fields.');
                }
            }
        }
    }
</script>

<style scoped>
    .contact-section{
        background-color: #f8f9fa;
    }

    .contact-info-section{
        background-color: #ffffff;
        padding: 3rem 0;
    }

    .contact-form-section{
        background-color:  #001f3f;
        padding: 3rem 0;
    }

    h2{
        color: white;
    }

    .form-label{
        font-weight: bold;
    }

    .btn-primary{
        background-color: #001f3f;
        border: none;
    }

    i{
        color: #001f3f;
    }

    p{
        font-size: 1.1rem;
    }
</style>