<template>
    <div class="home">
        <div class="hero-section text-center text-white animate__animated animate__fadeIn  align-items-center">
            <div class="container">
                <h1 class="display-3">Welcome to VogueVault</h1>
                <p class="lead">Where Fashion Meets Elegance</p>
               
            </div>
        </div>

        <!--Call to action section-->
       <!--  <div class="cta-section bg-navy text-white py-5">
            <div class="container text-center">
                <h2>Explore Our Exclusive Collections</h2>
                <p class="lead">Unique designs crafted to perfection. Get up to 30% off on select items today.</p>
                <a href="/shop" class="btn btn-light btn-lg mt-3">Start Shopping</a>
            </div>
        </div> -->

        <!--Featured Products Slider(Carousel)-->
       <!--  <div id="featuredProductsCarousel" class="carousel slide bg-light" data-bs-ride="carousel">
            <div class="carousel-inner">
                <div class="carousel-item active">
                    <img src="@/assets/featured-product1.jpg" class="d-block w-100" alt="Featured Product 1">
                    <div class="carousel-caption d-none d-md-block">
                        <h5>Exclusive Designer Gown</h5>
                        <p>Now available with a 15% discount!</p>
                    </div>
                </div>
                <div class="carousel-item">
                    <img src="/assets/featured-product2.jpg" class="d-block w-100" alt="Featured Product 2">
                    <div class="carousel-caption d-none d-md-block">
                        <h5>Luxury Handbags</h5>
                        <p>Step up your accessory game.</p>
                    </div>
                </div>
                <div class="carousel-item">
                    <img src="/assets/featured-product3.jpg" class="d-block w-100" alt="Featured Product 3">
                    <div class="carousel-caption d-none d-md-block">
                        <h5>Stylish Footwear</h5>
                        <p>Comfort meets style in every step.</p>
                    </div>
                </div>
            </div>
            <button class="carousel-control-prev" type="button" data-bs-target="#featuredProductsCarousel" data-bs-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Previous</span>
            </button>

            <button class="carousel-control-next" type="button" data-bs-target="#featuredProductsCarousel" data-bs-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Next</span>
            </button>
                
        </div>
 -->
        <!--Testimonials Section-->
        <div class="testimonials-section bg-light py-5">
            <div class="container text-center">
                <h2>What Our Customers Are Saying</h2>
                <div class="row">
                    <div class="col-md-4">
                        <div class="card shadow-sm border-0">
                            <div class="card-body">
                                <p class="card-text">"I love shopping at VogueVault! Their collections are always trendy, and the quality is unmatched."</p>
                                <h5 class="card-title">- Sarah J.</h5>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="card shadow-sm border-0">
                            <div class="card-body">
                                <p class="card-text">"The best online shopping experience I've ever had. Their customer care is outstanding"</p>
                                <h5 class="card-title">- Micheal P.</h5>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="card shadow-sm border-0">
                            <div class="card-body">
                                <p class="card-text">"I'm a huge fan of their exclusive pieces. VogueVault never disappoints!."</p>
                                <h5 class="card-title">- Amanda R.</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!--Newsletter Subscription Section-->
        <div class="newsletter-section bg-navy text-white py-5">
            <div class="container text-center">
                <h2>Subscribe to Our Newsletter</h2>
                <p class="lead">Stay updated with the latest fashion trends and exclusive offers!</p>
                <form @submit.prevent="subscribeNewsletter" class="row g-3 justify-content-center">
                    <div class="col-md-4">
                        <input type="email" class="form-control" placeholder="Enter your email" v-model="email" required>
                    </div>
                    <div class="col-md-2">
                        <button type="submit" class="btn btn-light w-100">Subscribe</button>
                    </div>
                </form>
            </div>
        </div>

        <!--Features Section-->
        <div class="features-section text-center py-5 bg-light text-navy">
            <div class="container">
                <h2 class="animate__animated animate__fadeInUp text-center mb-5">Why Choose Us?</h2>
                <p class="animate__animated animate__fadeInUp">Explore our unique collections and exclusive offers.</p>
            <div class="row mt-4">
                <div class="col-md-4 animate__animated animate__zoomIn">
                    <div class="card h-100 border-0 shadow-sm">
                    <div class="card-body text-center">
                    <i class="bi bi-star-fill" style="font-size:2rem; color: #001f3f;"></i>
                    <h4 class="card-title">Exclusive Designs</h4>
                    <p class="card-text">Each piece is crafted with perfection.</p>
                </div>
            </div>
        </div>
                <div class="col-md-4 animate__animated animate__zoomIn">
                    <div class="card h-100 border-0 shadow-sm">
                        <div class="card-body text-center">
                    <i class="bi bi-check-circle" style="font-size:2rem; color:#001f3f;"></i>
                    <h4 class="card-title">Quality Assurance</h4>
                    <p class="card-text">Top-notch quality for lasting elegance.</p>
                </div>
            </div>
        </div>
                <div class="col-md-4 animate__animated animate__zoomIn">
                    <div class="card h-100 border-0 shadow-sm">
                        <div class="card-body text-center">
                    <i class="bi bi-heart-fill" style="font-size:2rem; color: #001f3f;"></i>
                    <h4 class="card-title">Customer Care</h4>
                    <p class="card-text">We're here to assist you with anything.</p>
                </div>
            </div>
        </div>
    </div>
</div>
</div>
</div>
</template>

<script>
    export default{
        name:"HomeView",
        data(){
            return{
                email:''
            };
        },
        methods:{
            subscribeNewsletter(){
                alert('Thank you for subscribing with'+ ' ' + this.email);
                this.email=''
            }
        }
    };
</script>

<style scoped>
    .hero-section{
        background-image: url(@/assets/hero-bg.jpg);
        background-size: cover;
        background-position: center;
        padding: 150px 0;
        height: 100vh;
    }

    @media(max-width:768px){
        .hero-section{
            padding: 100px 0;
            text-align: center;
        }
    }

    .features-section{
        background-color: #f8f9fa;
    }

    .features-section h2{
        font-weight: bold;
    }

    .features-section .col-md-4:hover{
        transform: translateY(-10px);
    }

    .card-body{
        transition: transform 0.3s;
    }

    .card-body:hover{
        transform: translateY(-10px);
    }

    .bg-navy{
        background-color: #001f3f;
    }
    
    .cta-section h2, .testimonials-section h2{
        color:  #001f3f;
    }

    .card{
        transition: transform 0.3s;
    }

    .card:hover{
        transform: translateY(-10px);
    }

    .carousel .carousel-item img{
        height: 500px;
        object-fit: cover;
    }

    .newsletter-section h2{
        color: white;
    }
</style>