<template>
  <div id="app">
    <nav class="navbar navbar-expand-lg navbar-dark bg-navy fixed-top">
      <div class="container-fluid">
        <a class="navbar-brand" href="#">
          <img src="@/assets/logo.jpg" alt="VogueVault Logo" class="logo me-2">
          <span>VogueVault</span>
        </a>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarNav">
          <ul class="navbar-nav ms-auto">
            <li class="nav-item">  
              <router-link class="nav-link" to="/">Home</router-link>
            </li> 

            <li class="nav-item">  
              <router-link class="nav-link" to="/about">About Us</router-link>
            </li> 

            <li class="nav-item">  
              <router-link class="nav-link" to="/shop">Shop</router-link>
            </li> 

            <li class="nav-item">  
              <router-link class="nav-link" to="/contact">Contact Us</router-link>
            </li> 

            <li class="nav-item">  
              <router-link class="nav-link" to="/cart">Cart</router-link>
            </li> 
          </ul>
        </div>
      </div>
    </nav>
    <div class="container mt-5 pt-5">
      <router-view></router-view>
    </div>
    <footer class=" text-center bg-navy text-white py-4">
      <div class="container text-center">
        <div class="social-icons mb-3">
          <a href="https://www.facebook.com" target="_blank" class="text-navy me-3">
            <i class="bi bi-facebook"></i>
          </a>
          <a href="https://www.instagram.com" target="_blank" class="text-navy me-3">
            <i class="bi bi-instagram"></i>
          </a>
          <a href="https://www.facebook.com" target="_blank" class="text-navy me-3">
            <i class="bi bi-twitter"></i>
          </a>
        </div>
      
      <p>&copy; 2024 VogueVault - All Rights Reserved</p>
    </div>
    </footer>
  </div>
</template>

<script>

export default {
  name: 'App',
 
};
</script>

<style>
body {
  font-family: 'Helvetica Neue', Arial, sans-serif;
  background-color: #f4f4f4;
}

/* .navbar{
  
  border-top: 1px solid #e9ecef;
  position: fixed;
  width: 100%;
  bottom: 0;
} */

.navbar.bg-navy{
  background-color: #001f3f;
}

.navbar .nav-link:hover{
  border-bottom: 2px solid white;
}

footer{
  background-color: #001f3f;
  border-top: 1px solid #e9ecef;
}

.navbar .nav-link{
  color: white;
  font-weight: 500;
}

.logo{
  height:40px;
  width: auto;
}

.footer .social-icons i{
  font-size: 1.5rem;
  transition: color 0,3s ease;
}

.footer .social-icons a:hover{
  color: #001f3f;
}

@media(max-width:768px){
  .navbar .nav-link{
    margin: 5px 0;
  }
  .navbar .navbar-collapse{
    text-align: center;
  }
}
</style>
