<template>
    <div class="shop container">
        <h2 class="text-center text-navy my-5">Our Collection</h2>
        <div class="container">
        <div class="row">
            <div v-for="product in products" :key="product.id" class="col-md-4 mb-4">
                <ProductCard :product="product" @add-to-cart="handleAddToCart" />
            </div>
        </div>
    </div>
</div>
</template>

<script>
    import ProductCard from '@/components/ProductCard.vue';

    export default{
        name:'ShopView',
        components:{
            ProductCard,
        },
        data(){
            return{
                products:[ {
                    id:1, name:'Designer Dress', image:require('@/assets/dress1.jpg'), description:'A beautiful designer dress for special occasions.', price:299000,
                },
                {
                    id:2, name:'Casual Shirt', image:require('@/assets/shirt1.jpg'), description:'A stylish casual shirt for everyday wear.', price:79000,
            },

          {
                id:3, name:'Luxury Watch', image:require('@/assets/watch1.jpg'), description:'An elegant watch to complement any outfit.', price:549000,
        },
        {
                id:4, name:'Trendy Outfit', image:require('@/assets/product1.jpg'), description:'A trendy outfit for any occasion.', price:120000,
        },
        {
                id:5, name:'Gown', image:require('@/assets/product2.jpg'), description:'An elegant gown for formal events.', price:250000,
        },
        {
                id:6, name:'Casual Jacket', image:require('@/assets/product3.jpg'), description:'A casual yet stylish jacket.', price:85000,
        },
        {
                id:7, name:'Office Wear', image:require('@/assets/product4.jpg'), description:'Sleek and modern office wear.', price:110000,
        },
        {
                id:8, name:'Daily Wear', image:require('@/assets/product5.jpg'), description:'Comfortable and chic daily wear.', price:70000,
        },
        {
                id:9, name:'Street Wear', image:require('@/assets/product6.jpg'), description:'Bold and unique street style.', price:90000,
        },
        {
                id:10, name:'Classic Coat', image:require('@/assets/product7.jpg'), description:'Classic coat for all seasons.', price:150000,
        },
        {
                id:11, name:'Formal Attire', image:require('@/assets/product8.jpg'), description:'Elegant and formal attire.', price:300000,
        },
        {
                id:12, name:'Summer Wear', image:require('@/assets/product9.jpg'), description:'Casual summer wear for beach vibes.', price:65000,
        },
        {
                id:13, name:'Modern Shoe', image:require('@/assets/product10.jpg'), description:'Comfortable shoes with a modern design.', price:95000,
        },
        {
                id:14, name:'Classy Handbag', image:require('@/assets/product11.jpg'), description:'Chic and classy handbag.', price:135000,
        },
        {
                id:15, name:'Stylish Hat', image:require('@/assets/product12.jpg'), description:'Stylish hat for sunny days.', price:40000,
        },
        {
                id:16, name:'Scarf', image:require('@/assets/product13.jpg'), description:'A fashionable scarf to accessorize.', price:45000,
        },
        {
                id:17, name:'Modern Sunglasses', image:require('@/assets/product14.jpg'), description:'Modern sunglasses with UV protection.', price:80000,
        },
        {
                id:18, name:'Heels', image:require('@/assets/product15.jpg'), description:'A classy pair of heels.', price:125000,
        },
        // {
        //         id:19, name:'Sneakers', image:require('@/assets/product16.jpg'), description:'Trendy sneakers for everyday wear.', price:105000,
        // },
    ]
            };
        },
        methods:{
            handleAddToCart(product){
                console.log('Product added to cart:', product);
            }
        }
    };
</script>

<style scoped>
    .shop-container{
        background-color: #f8f9fa;
    }
</style>