<template>
    <div class="about py-5 bg-light text-navy">
        <!--About Us Section-->
        <div class="about-section text-center py-5">
            <h1 class="animate__animated animate__fadeInUp">About VogueVault</h1>
            <p class="lead animate__animated animate__fadeInUp animate__delay-1s">VogueVault is your destination for exclusive designer collections. We bring the latest in fashion trends directly to you.
                                                                                    Each piece is carefully curated to ensure top-notch quality and lasting style.
            </p>
        </div>

        <!--Mission Section-->
        <div class="mission-section container py-5">
            <div class="row align-items-center">
                <div class="col-md-6 animate__animated animate__fadeInLeft">
                    <h2>Our Mission</h2>
                    <p>At VogueVault, our mission is to redefine fashion by offering unique designs, unparalleled quality, and an unforgettable shopping experience. We believe in fashion that inspires and empowers.</p>
                </div>
                <div class="col-md-6 animate__animated animate__fadeInRight">
                    <img src="@/assets/mission.jpg" alt="Our Mission" class="img-fluid rounded shadow-sm">
                </div>
            </div>
        </div>

        <!--Team Section-->
        <div class="team-section container py-5 text-center">
            <h2 class="animate__animated animate__fadeInUp">Meet Our Team</h2>
            <div class="row mt-4">
                <div class="col-md-4 animate__animated animate__zoomIn">
                    <img src="@/assets/team1.jpg" alt="Team Member 1" class="img-fluid rounded-circle mb-3">
                    <h4>Jane Doe</h4>
                    <p>Founder & CEO</p>
                </div>
                <div class="col-md-4 animate__animated animate__zoomIn">
                    <img src="@/assets/team2.jpg" alt="Team Member 2" class="img-fluid rounded-circle mb-3">
                    <h4>John Smith</h4>
                    <p>Creative Director</p>
                </div>
                <div class="col-md-4 animate__animated animate__zoomIn">
                    <img src="@/assets/team3.jpg" alt="Team Member 3" class="img-fluid rounded-circle mb-3">
                    <h4>Emily Johnson</h4>
                    <p>Lead Design</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default{
        name:'AboutView'
    }
</script>

<style scoped>
    .about-section{
        background-color: #f8f9fa;
    }

    .mission-section{
        background-color: #ffffff;
        padding: 3rem 0;
    }

    .team-section img{
        max-width: 150px;
    }

    h1{
        color: #001f3f;
    }

    p{
        font-size: 1.1rem;
    }
</style>