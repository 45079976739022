
import {createRouter, createWebHistory } from 'vue-router';
import HomeView from '@/views/HomeView.vue';
import ShopView from '@/views/ShopView.vue';
import AboutView from '@/views/AboutView.vue';
import ContactView from '@/views/ContactView.vue';
import CartView from '@/views/CartView.vue';



const routes=[
    {path:'/', component:HomeView},
    {path:'/shop', component:ShopView},
    {path:'/about', component:AboutView},
    {path:'/contact', component:ContactView},
    {path:'/cart', component:CartView},
    
];

const router=createRouter({
    history:createWebHistory(),
    routes,
});

export default router;