<template>
    <div class="product-card mb-4">
       <div class="card h-100 shadow-sm border-0">
        <img :src="product.image" class="card-img-top" :alt="product.name" />
        <!--Card Body-->
        <div class="card-body text-center">
            <h5 class="card-title">{{ product.name }}</h5>
            <p class="card-text">{{ product.description }}</p>
            <p class="card-text text-navy"><strong>&#8358;{{ product.price }}</strong></p>
            <a href="#" class="btn btn-primary" @click="addToCart(product)">Add to Cart</a>
        </div>
       </div>
    </div>
</template>

<script>
    export default{
        props:{
            product:{
                type:Object,
                required:true
        }
    },
    methods:{
        addToCart(product){
            this.$emit('add-to-cart', product);
            this.$cart.addItem(product);
            // alert{{product.name}} + 'has been added to your cart.';
        }
    }
    };
</script>

<style scoped>
    .card-title{
        font-weight: bold;
    }

    .product-card:hover{
        transform: translateY(-10px);
    }

    .card-body:hover{
        transform: scale(1.05);
    }

    .card-body{
        transition: transform 0.3s ease;
    }

    .btn-primary{
        background-color: #001f3f;
        border: none;
    }
</style>